import React from "react";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import PersonIcon from "@material-ui/icons/Person";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import ComputerIcon from "@material-ui/icons/Computer";
import GestureIcon from "@material-ui/icons/Gesture";
import FaceIcon from "@material-ui/icons/Face";
import SportsEsportsIcon from "@material-ui/icons/SportsEsports";
import ChatIcon from "@material-ui/icons/Chat";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import LanguageIcon from "@material-ui/icons/Language";
import ListIcon from "@material-ui/icons/List";
import HttpIcon from "@material-ui/icons/Http";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import GitHubIcon from "@material-ui/icons/GitHub";
import LaunchIcon from "@material-ui/icons/Launch";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import MailIcon from "@material-ui/icons/Mail";
import AppsIcon from "@material-ui/icons/Apps";
import CloudIcon from "@material-ui/icons/Cloud";
import AutoModeIcon from "@material-ui/icons/Autorenew";

import "./Card.css";

function Card(props) {
  let images = {
    LocationOnIcon: <LocationOnIcon fontSize="large" id="imageIcon" />,
    PhoneIcon: <PhoneIcon fontSize="large" id="imageIcon" />,
    PersonIcon: <PersonIcon fontSize="large" id="imageIcon" />,
    EmailIcon: <EmailIcon fontSize="large" id="imageIcon" />,
    PhoneIphoneIcon: <PhoneIphoneIcon fontSize="large" id="imageIcon" />,
    ComputerIcon: <ComputerIcon fontSize="large" id="imageIcon" />,
    GestureIcon: <GestureIcon fontSize="large" id="imageIcon" />,
    FaceIcon: <FaceIcon fontSize="large" id="imageIcon" />,
    SportsEsportsIcon: <SportsEsportsIcon fontSize="large" id="imageIcon" />,
    ChatIcon: <ChatIcon fontSize="large" id="imageIcon" />,
    RecordVoiceOverIcon: (
      <RecordVoiceOverIcon fontSize="large" id="imageIcon" />
    ),
    LanguageIcon: <LanguageIcon fontSize="large" id="imageIcon" />,
    ListIcon: <ListIcon fontSize="large" id="imageIcon" />,
    HttpIcon: <HttpIcon fontSize="large" id="imageIcon" />,
    DriveEtaIcon: <DriveEtaIcon fontSize="large" id="imageIcon" />,
    GitHubIcon: <GitHubIcon fontSize="large" id="imageIcon" />,
    LaunchIcon: <LaunchIcon fontSize="large" id="imageIcon" />,
    LinkedInIcon: <LinkedInIcon fontSize="large" id="imageIcon" />,
    MailIcon: <MailIcon fontSize="large" id="imageIcon" />,
    AppsIcon: <AppsIcon fontSize="large" id="imageIcon" />,
    CloudIcon: <CloudIcon fontSize="large" id="imageIcon" />,
    AutoModeIcon: <AutoModeIcon fontSize="large" id="imageIcon" />,
  };

  let projectsDisc = {
    "Face Detection and Recognition": (
      <p>
        Developed a program using <strong>OpenCV</strong> that recognizes number
        of individuals present in the picture or video, and create a separate
        image of those faces.
      </p>
    ),
    "TCP-Chatroom": (
      <p>
        {/* TCP Chatroom like twitch in python resume description */}
        Developed a <strong>multi-client chatroom</strong> using{" "}
        <strong>Python</strong> and <strong>Socket Programming</strong> which
        allows multiple clients to connect to a server and chat with each other.{" "}
        <br /> <br /> Admin can also kick out or ban any user from the chatroom.
      </p>
    ),
    "Voice Assistant": (
      <p>
        {/* Voice Assistant app using Kotlin and Android studio resume description */}
        Developed a <strong>Voice Assistant</strong> app using{" "}
        <strong>Kotlin</strong> and <strong>Android Studio</strong>.
        <br />
        <br />
        App can perform basic tasks like opening apps, playing music, searching
        on the internet, setting alarms, display calendar events and many more.
        <br />
        <br />
        Incorporated <strong>Google Text-to-Speech</strong> and{" "}
        <strong>Speech Recognition</strong> libraries to make the app more
        interactive.
      </p>
    ),
    RideX: (
      <p>
        Formulated a ride-sharing app using <strong>Android Studio</strong> as
        part of a 3-person team, empowering users to efficiently locate and
        divide transportation expenses.
        <br />
        <br />
        Created multiple features including confirmation, posting, searching,
        and real-time chatting.
      </p>
    ),
    "Website Portfolio": (
      <p>
        Developed a <strong>Portfolio Website</strong> using{" "}
        <strong>React</strong> and <strong>Material UI</strong> that showcases
        my skills and projects.
      </p>
    ),
    OGSA: (
      <p>
        {/* E-commerce resume description */}
        Functional e-commerce ticketing website using Agile workflow, resulting
        in <strong>600+ registered</strong> users and{" "}
        <strong>500+ processed</strong> secure payments through the integration
        of <strong>Stripe API</strong> and Firebase{" "}
        <strong>Cloud Functions</strong>
        <br />
        <br />
        Established role-based permissions and utilized Firebase database
        real-time sync feature to efficiently checked-in{" "}
        <strong>1000+ guests</strong> and safeguard sensitive data.
      </p>
    ),
    Puzzle: (
      <p>
        {/* 8-Puzzle solver using A* Search algorithm resume description */}
        Solves X number of randomly generated <strong>
          8 and 15 Puzzle
        </strong>{" "}
        using <strong>A* Search</strong> algorithm
        <br />
        <br />
        Compare results between <strong>3 heuristics</strong> (Hamming Distance,
        Manhattan Distance and Manhattan Distance + Linear Conflict) to
        determine which one is more <strong>efficient</strong>. <br /> <br />
        It is developed using <strong>Python</strong> and <strong>Tree</strong>{" "}
        data structure is used to store the states.
      </p>
    ),
  };

  return (
    <div className="card">
      {images[props.imageName]}
      <h3> {props.title}</h3>
      <span>
        {props.subtitle in projectsDisc ? (
          projectsDisc[props.subtitle]
        ) : props.textAlign === "center" ? (
          <p style={{ textAlign: "center" }}>{props.subtitle}</p>
        ) : (
          <p>{props.subtitle}</p>
        )}
      </span>
      {(props.link || props.link2) && (
        <span id="linkSection">
          <h2>
            {props.link && (
              <a href={props.link} target="_blank">
                <GitHubIcon />
              </a>
            )}
            {props.link2 && (
              <a href={props.link2} target="_blank" id="link2">
                <LaunchIcon />
              </a>
            )}
          </h2>
        </span>
      )}
    </div>
  );
}

export default Card;
